//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import UMUM from "../../library/umum";
import DATAMASTER from "../../library/dataMaster";
export default {
  data() {
    return {
      

      form : {
        id : '',
        tahun : '',
        uraian : '',
        jenis : 0,
        kelas : 0,
        sub_unit_kerja : '',
        delegasi : '',
        pemberi_delegasi : '',
        master_jenis_sasaran_ku : 3,
        sasaran_induk : '',
        status_sasaran : 0,
        keterangan : '',
        file : null,
        nip : '',
      },

      indikator : {
        id : '',
        pk_unit_sasaran : '',
        uraian : '',
        perspektif : 1,
        target : 0,
        satuan : '%',
        uraian_kuantitas : '',
        target_kuantitas : 0,
        satuan_kuantitas : '',
        uraian_waktu : '',
        target_waktu : 0,
        satuan_waktu : 'Bulan',
        tahun : '',
        keterangan : '',
      },

      id : '',
      jns_golongan_id : '',
      list_data : [],
      list_indikator : [],

      list_interfensi : [],


      page_first: 1,
      page_last: 0,
      page_limit : 10,
      cari_value: "",
      cek_load_data : false,


      mdl_add: false,
      mdl_edit: false,
      mdl_hapus : false,
      mdl_chat : false,
      mdl_maps : false,
      btn_add: false,
      mdl_maps_tambah : false,
      mdl_img : false,
      mdl_lihat_data : false,

      mdl_list_indikator : false,
      mdl_add_indikator : false,
      mdl_edit_indikator : false,
      mdl_hapus_indikator : false,

      marginFilter : '',

      UMUM : UMUM,
      DATAMASTER : DATAMASTER,
    }
  },
  methods: {


    getData : function(){
      this.cek_load_data = true;
      fetch(this.$store.state.url.URL_kinerja_pk_unit_sasaran + "view", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              tahun: this.form.tahun,
              delegasi : this.form.delegasi,
              kelas : this.form.kelas,
              data_ke: this.page_first,
              cari_value: this.cari_value
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_data = res_data.data;
              this.page_last = res_data.jml_data;
              this.cek_load_data = false;
              // console.log(res_data);
      });
    },


    addData : function() {
      fetch(this.$store.state.url.URL_kinerja_pk_unit_sasaran + "addData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      }).then(res_data => {
          this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
          this.getData();
      });
    },


    editData : function(){

      fetch(this.$store.state.url.URL_kinerja_pk_unit_sasaran + "editDataUMUM", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      }).then(res_data => {
          this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
          this.getData();
      });
    },

    removeData : function(idnya){
      fetch(this.$store.state.url.URL_kinerja_pk_unit_sasaran + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getData();
      });

    },

    selectData : function(data){

      // console.log(data)


      this.form.id = data.id;
      this.form.tahun = data.tahun;
      this.form.uraian = data.uraian;
      this.form.jenis = data.jenis;
      this.form.sasaran_induk = data.sasaran_induk ;
      // this.form.delegasi = data.delegasi;
      this.form.keterangan = data.keterangan;

      this.form.pemberi_delegasi = data.pemberi_delegasi;
      this.form.master_jenis_sasaran_ku = data.master_jenis_sasaran_ku;
      this.form.status_sasaran = data.status_sasaran;

      this.indikator.pk_unit_sasaran = data.id
      this.indikator.tahun = this.form.tahun


      this.getIndikator();

    },



    // ========================================= INDIKATOR =========================================
    getIndikator : function() {
      fetch(this.$store.state.url.URL_kinerja_pk_unit_sasaran_indikator + "view2", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.indikator)
      })
      .then(res => res.json())
      .then(res_data => {
          // console.log(res_data)
          this.list_indikator = res_data
          
      });
    },

    addIndikator : function() {
      fetch(this.$store.state.url.URL_kinerja_pk_unit_sasaran_indikator + "addData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.indikator)
      }).then(res_data => {
          this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
          // alert("Sukses")
          this.getIndikator();
          this.getData();
      });
    },

    updateIndikator : function() {
      fetch(this.$store.state.url.URL_kinerja_pk_unit_sasaran_indikator + "editData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.indikator)
      }).then(res_data => {
          this.Notify('Sukses Merubah Data', 'primary', 'check_circle_outline');
          this.getIndikator();
      });
    },


    removeIndikator : function(idnya){
      fetch(this.$store.state.url.URL_kinerja_pk_unit_sasaran_indikator + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.indikator)
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getIndikator();
          this.getData();
      });

    },


    selectIndikator : function(data) {
      this.indikator = {
        id : data.id,
        pk_unit_sasaran : data.pk_unit_sasaran,
        perspektif : data.perspektif,
        uraian : data.uraian,
        target : data.target,
        satuan : data.satuan,
        uraian_kuantitas : data.uraian_kuantitas,
        target_kuantitas : data.target_kuantitas,
        satuan_kuantitas : data.satuan_kuantitas,
        uraian_waktu : data.uraian_waktu,
        target_waktu : data.target_waktu,
        satuan_waktu : data.satuan_waktu,
        tahun : data.tahun,
        keterangan : data.keterangan,
      };
    },
    // ========================================= INDIKATOR =========================================


      getProfile: async function() {
        //   this.cek_load_data = true;
            return new Promise((resolve, reject) => {

              fetch(this.$store.state.url.URL_kinerja_asn + "view", {
              method: "POST",
              headers: {
                  "content-type": "application/json",
                  authorization: "kikensbatara " + localStorage.token
              },
              body: JSON.stringify({
                  nip : this.form.nip
              })
              })
              .then(res => res.json())
              .then(res_data => {
                  // console.log(res_data)
                  var datax = res_data[0]
                  this.form.pemberi_delegasi = datax.penilai_jabatan_id
                  resolve(res_data)
              });

            })
        },


      getInterfensi: async function() {
      //   this.cek_load_data = true;
          return new Promise((resolve, reject) => {

            fetch(this.$store.state.url.URL_kinerja_pk_unit_sasaran + "list", {
              method: "POST",
              headers: {
                "content-type": "application/json",
                authorization: "kikensbatara " + localStorage.token
              },
              body: JSON.stringify({
                  delegasi : this.form.pemberi_delegasi,
                  tahun : this.form.tahun
              })
          })
              .then(res => res.json())
              .then(res_data => {
                  // console.log(res_data);
                  this.list_interfensi = res_data;
                  resolve(res_data)
          });

          })
      },




    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },

        indexing : function(index){
            var idx = ((this.page_first-1)*this.page_limit)+index
            return idx;
        },
        
        btn_prev : function(){
            this.cek_load_data = true;
            if(this.page_first>1){
                this.page_first--
            }else{
                this.page_first = 1;
            }
            this.getData();
        },

        btn_next : function(){
            if(this.page_first >= this.page_last){
                this.page_first == this.page_last
            }else{
                this.page_first++;
            }
            this.getData();
        },

        cari_data : function(){
            this.page_first = 1;
            this.getData();
        },

        tunggu : async function(){
          await this.getProfile();
          await this.getInterfensi();
          await this.DATAMASTER.getPerspektif()
          this.getData();
        },

        indicatorColor : function(data){
          if (data == 0) {
            return {
              bg : `bg-orange-2`,
              status : false
            }  
          }
          else if(data == 1){
            return {
              bg : `bg-blue-1`,
              status : false
            }
          }
          else if (data == 2){
            return {
              bg : `bg-red-2`,
              status : true
            }
          }

        },

        checkJenis(){
          if (this.form.jenis == 1) {
            this.form.sasaran_induk = null
          } 
        },


    // ====================================== PAGINATE ====================================







  },

  mounted () {

    // this.$store.commit("listJeniskategorilokasi");
    // this.$store.commit("getStorage");

    if(this.$q.platform.is.mobile){
      this.marginFilter='q-x-sm'
    }
    else{
      this.marginFilter=''
    }

    const d = new Date();
    this.form.tahun = d.getFullYear();



    var get_profile = JSON.parse(localStorage.profile);
    this.form.sub_unit_kerja = get_profile.profile.unit_kerja;
    this.form.delegasi = get_profile.profile.jabatan;
    this.form.nip = get_profile.profile.nip;
    // console.log(get_profile)

    this.tunggu();

  },
}
